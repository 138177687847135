import { IWidgetConfigurationContext, WithCss } from "@sgwt-widget/core";
import { Component, h } from "preact";

import * as moment from "moment";
import { DefaultDateFormat } from "common/constants";
import { IDateRangeValues } from "gallery-daterange-picker/gallery-daterange-picker.types";
import * as style from "./DateRangePicker.less";

// tslint:disable-next-line:no-var-requires
const ReactDatetime = require("react-datetime");

export interface IProps {
  fromDate?: string;
  toDate?: string;
  displayDateFormat: string;
  outputDateFormat: string;
  fromDatePlaceholder: string;
  toDatePlaceholder: string;
  calendarLocale: string;
  fromDateLabel: string;
  toDateLabel: string;
  onSelect: (values: IDateRangeValues) => void;
}

interface IState {
  fromDate?: moment.Moment;
  toDate?: moment.Moment;
}

export class DateRangePicker extends Component<IProps, IState> {
  public context!: IWidgetConfigurationContext;
  private fromPicker?: HTMLElement;
  private toPicker?: HTMLElement;

  constructor() {
    super();

    // we bind the this of the callbacks to this object
    this.onFromClick = this.onFromClick.bind(this);
    this.onToClick = this.onToClick.bind(this);
    this.onFromBlur = this.onFromBlur.bind(this);
    this.onToBlur = this.onToBlur.bind(this);
    this.isValidFrom = this.isValidFrom.bind(this);
    this.isValidTo = this.isValidTo.bind(this);
  }

  /**
   * method called before mounting the component
   */
  public componentWillMount() {
    // we initialize attributes and state
    if (this.props) {
      if (this.props.fromDate) {
        const fromDate = moment(this.props.fromDate, DefaultDateFormat);
        this.setState({
          fromDate
        });
      }
      if (this.props.toDate) {
        const toDate = moment(this.props.toDate, DefaultDateFormat);
        this.setState({
          toDate
        });
      }
    }
  }

  public render() {
    const pickerJSX = this.getPicker();
    return pickerJSX;
  }

  private toggleCalendar(
    picker: any,
    e?: MouseEvent | TouchEvent | KeyboardEvent
  ) {
    if (e) {
      e.preventDefault();
    }
    if (picker) {
      picker.setState({ open: !picker.state.open });
    }
  }

  private getCurrentConfiguration(placeholder: string) {
    return {
      closeOnSelect: true,
      dateFormat: this.props.displayDateFormat,
      input: true,
      inputProps: {
        placeholder,
      },
      locale: this.props.calendarLocale,
      timeFormat: false,
    };
  }

  private getPicker(): JSX.Element {
    const fromDateProps = this.getCurrentConfiguration(this.props.fromDatePlaceholder);
    const toDateProps = this.getCurrentConfiguration(this.props.toDatePlaceholder);

    return (
      <WithCss styles={style}>
        <div class={"p-0 col-md-12 col-xs-12 " + style.locals.daterangepicker}>
          <div className="input-group">
            <span class="input-group-btn">
              <button class={`btn btn-secondary`} onClick={e => { this.toggleCalendar(this.fromPicker, e); }} type="button">{this.props.fromDateLabel}</button>
            </span>
            <span style={{width: "30%"}}>
              <ReactDatetime
                ref={(el: any) => { this.fromPicker = el;}}
                className=""
                {...fromDateProps}
                onBlur={this.onFromBlur}
                onChange={this.onFromClick}
                value={
                  this.state.fromDate
                    ? this.state.fromDate
                    : undefined
                }
                isValidDate={this.isValidFrom}
              />
            </span>
            <span className="input-group-btn pl-0 pl-sm-2">
              <button className="btn btn-secondary" onClick={e => { this.toggleCalendar(this.toPicker, e); }} type="button">{this.props.toDateLabel}</button>
            </span>
            <span style={{width: "30%"}}>
              <ReactDatetime
                ref={(el: any) => { this.toPicker = el; }}
                className=""
                {...toDateProps}
                onBlur={this.onToBlur}
                onChange={this.onToClick}
                value={
                  this.state.toDate
                    ? this.state.toDate
                    : undefined
                }
                isValidDate={this.isValidTo}
              />
            </span>
          </div>
        </div>
      </WithCss>
    );
  }

  private onFromClick(fromDate: moment.Moment) {
    this.updateFromDate(fromDate);
  }

  private onToClick(toDate: moment.Moment) {
    this.updateToDate(toDate);
  }

  private onFromBlur(fromDate: moment.Moment) {
    if (fromDate) {
      this.updateFromDate(fromDate);
    } else {
      const toEmit: IDateRangeValues = {
        fromDate: undefined,
      };
      if (this.state.toDate) {
        toEmit.toDate = this.state.toDate.format(this.props.outputDateFormat);
      }      
      this.props.onSelect({ ...toEmit });
      this.setState({
        fromDate: undefined,
      });
    }
  }

  private onToBlur(toDate: moment.Moment) {
    if (toDate) {
      this.updateToDate(toDate);
    } else {
      const toEmit: IDateRangeValues = {
        toDate: undefined,
      };
      if (this.state.fromDate) {
        toEmit.fromDate = this.state.fromDate.format(this.props.outputDateFormat);
      }      
      this.props.onSelect({ ...toEmit });
      this.setState({
        toDate: undefined,
      });
    }
  }

  private updateFromDate(fromDate: moment.Moment) {
    if (moment(fromDate).isValid()) {
      /**
       * If the start date was changed we emit an event
       */
      const momentFromDate = moment(fromDate);
      if (!momentFromDate.isSame(this.state.fromDate)) {
        const toEmit: IDateRangeValues = {
          fromDate: momentFromDate.format(this.props.outputDateFormat),
        };
        if (this.state.toDate) {
          toEmit.toDate = this.state.toDate.format(this.props.outputDateFormat);
        }
        this.props.onSelect({ ...toEmit });
      }

      this.setState({
        fromDate,
      });
    }
  }
  
  private updateToDate(toDate: moment.Moment) {
    if (moment(toDate).isValid()) {
      /**
       * If the end date was changed we emit an event
       */
      const momentToDate = moment(toDate);
      if (!momentToDate.isSame(this.state.toDate)) {
        const toEmit: IDateRangeValues = {
          toDate: momentToDate.format(this.props.outputDateFormat),
        };
        if (this.state.fromDate) {
          toEmit.fromDate = this.state.fromDate.format(
            this.props.outputDateFormat
          );
        }
        this.props.onSelect({ ...toEmit });
      }

      this.setState({
        toDate,
      });
    }
  }
  
  /**
   * method that checks if the fromDate is valid
   */
  private isValidFrom(currentDate: moment.Moment) {
    if (this.state.toDate) {
      return currentDate.isBefore(this.state.toDate);
    } else {
      return true;
    }
  }

  /**
   * method that checks if the toDate is valid
   */
  private isValidTo(currentDate: moment.Moment) {
    if (this.state.fromDate) {
      return currentDate.isAfter(this.state.fromDate);
    } else {
      return true;
    }
  }
}
