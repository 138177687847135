import {
  h,
  initializeWidgetConfiguration,
  SgWidget,
  WidgetConfigurationProvider,
} from "@sgwt-widget/core";
import { ComponentProps, emit, props } from "skatejs";

import { ObjectHelper } from "../common/helpers";
import { WithSGB4 } from "Sgb4/common/WithSGB4/WithSGB4";
import { WidgetTracker } from "common/component/WidgetTracker/WidgetTracker";
import { IDateRangeValues } from "./gallery-daterange-picker.types";
import { DefaultDateFormat, DisplayDateFormat } from "common/constants";
import { DateRangePicker } from "./components/DateRangePicker";

export interface IProps {
  eventNameToEmit: string;
  outputDateFormat: string;
  displayDateFormat: string;
  fromDatePlaceholder: string;
  toDatePlaceholder: string;
  fromDateAttribute: string;
  toDateAttribute: string;
  calendarLocale: string;
  fromDateLabel: string;
  toDateLabel: string;
  userSelection: { [outputAttribute: string]: any };
}

export interface IState {
  fromDate: string;
  toDate: string;
}

export class GalleryDateRangePicker extends SgWidget<IProps> {
  public static is = "gallery-daterange-picker";

  public static props: ComponentProps<IProps> = {
    eventNameToEmit: { ...props.string, default: GalleryDateRangePicker.is },
    outputDateFormat: { ...props.string, default: DefaultDateFormat },
    displayDateFormat: { ...props.string, default: DisplayDateFormat },
    fromDatePlaceholder: { ...props.string, default: "Start date" },
    toDatePlaceholder: { ...props.string, default: "End date" },
    fromDateAttribute: {...props.string, default: "fromDate"},
    toDateAttribute: {...props.string, default: "toDate"},
    fromDateLabel: {...props.string, default: "From"},
    toDateLabel: {...props.string, default: "To"},
    calendarLocale: {...props.string, default: "en"},
    userSelection: props.object,
  };

  private firstTime: boolean = true;

  public render(currentProps: IProps) {

    /**
     * The method used by the preact component to emit the new selection when it's changed
     */
    const onSelect = (values: IDateRangeValues) => {
      const newValues: any = {};

      if (values.fromDate) {
        newValues[currentProps.fromDateAttribute] = values.fromDate;
      }

      if (values.toDate) {
        newValues[currentProps.toDateAttribute] = values.toDate;
      }

      // we emit the event with the proper event name
      this.emitEvent(newValues)
    };

    const fromDate = currentProps.userSelection
      ? currentProps.userSelection[currentProps.fromDateAttribute]
      : null;

    const toDate = currentProps.userSelection
      ? currentProps.userSelection[currentProps.toDateAttribute]
      : null;

    const valueToEmit: any = {}
    if (fromDate) {
      valueToEmit[currentProps.fromDateAttribute] = fromDate;
    }
    if (toDate) {
      valueToEmit[currentProps.toDateAttribute] = toDate;
    }

    if (!ObjectHelper.isEmpty(valueToEmit)) {
      this.emitEvent(valueToEmit);
    }

    return (
      <WidgetConfigurationProvider
        widgetConfiguration={this.widgetConfiguration}
      >
        <WithSGB4 extraUrls={["https://shared.sgmarkets.com/widgets/sgwt-datetime-picker/v4/sgwt-datetime-picker.css"]}>
          <DateRangePicker
            fromDate={fromDate}
            toDate={toDate}
            displayDateFormat={this.props.displayDateFormat}
            outputDateFormat={this.props.outputDateFormat}
            fromDatePlaceholder={this.props.fromDatePlaceholder}
            toDatePlaceholder={this.props.toDatePlaceholder}
            fromDateLabel={this.props.fromDateLabel}
            toDateLabel={this.props.toDateLabel}
            calendarLocale={this.props.calendarLocale}
            onSelect={onSelect}
          />
          <WidgetTracker widgetName={GalleryDateRangePicker.is} />
        </WithSGB4>
      </WidgetConfigurationProvider>
    );
  }

  // After the first time the selector is rendered we emit a ready event
  public rendered() {
    if (this.firstTime) {
      this.firstTime = false;

      const eventName = `${GalleryDateRangePicker.is}--ready`;
      this.widgetConfiguration.debug(`Event emitted - Name: '${eventName}'`);
      this.emitEvent({})
    }
  }

  private getEventNameToEmit(): string {
    const { eventNameToEmit } = this.props;
    return eventNameToEmit || `${GalleryDateRangePicker.is}--value-changed`;
  }

  private emitEvent(valueToEmit: any) {
    const eventName = this.getEventNameToEmit();

    if (ObjectHelper.isEmpty(valueToEmit)) {
      this.widgetConfiguration.debug(`Event emitted - Name: '${eventName}'`);
    } else {
      this.widgetConfiguration.debug(`Event emitted - Name: '${eventName}' | Content: ${JSON.stringify(valueToEmit)}` );
    }

    valueToEmit.requestSeqNum = 0;
    emit(this, eventName, { detail: valueToEmit });
  }  
}

initializeWidgetConfiguration(GalleryDateRangePicker, {
  neverUseShadowDOM: true,
});

customElements.define(GalleryDateRangePicker.is, GalleryDateRangePicker);
